<template>
  <div class="block__subtitle mb-40">{{ title }}</div>
  <div class="block__control">
    <div class="block__field">
      <VSearch
        name="searchCategory"
        placeholder="Поиск"
        class-input="input-border-all input-padding-25 input-height-48"
        v-model="input"
      />
    </div>

    <div class="admin__button">
      <VButton mod="plus" @click="openModalClickAdd">Добавить</VButton>
    </div>
  </div>

  <VTable
    :head-row="headRow"
    :touch-bar-list="touchBarCategoryAdmin"
    url-set-rows="/references/category"
    url-action="/references/category/action"
    :transform-object="getCategoryFn"
    :request-is-auth="false"
    @link="handlerLink"
  />

  <teleport to="body">
      <VModal
        @close="closeAndClear"
        v-if="showVModal"
       >
        <h4 class="modal__title mb-40">Категория</h4>

        <form class="form" @submit.prevent="onSubmit">
          <div class="form__inner">
            <VInput
              id="name"
              name="name"
              placeholder="Название*"
              v-model="formData.name"
              :errors="v$.name.$errors"
              :server-errors="serverErrors.value?.name"
              @blur="validateField"
              @input="validateField"
              :class-input="['input input-placeholder-black']"
            />

            <VCheckbox
              id="check"
              name="check"
              class-form="form-checkbox form__control__modal mb-20"
              class-label="bg-white"
              is-switch
              :value="formData.is_active"
              v-model="formData.is_active"
            >
              <template #default="slotProps">
                <label :for="slotProps.for" :class="slotProps.class">
                  {{ formData.is_active ? 'Активно' : 'Не активно' }}
                </label>
              </template>
            </VCheckbox>

            <VCheckbox
              id="check-smr"
              name="check-smr"
              class-form="form-checkbox form__control__modal mb-20"
              class-label="bg-white"
              is-switch
              :value="formData.is_construction"
              v-model="formData.is_construction"
            >
              <template #default="slotProps">
                <label :for="slotProps.for" :class="slotProps.class">
                  {{ formData.is_construction ? 'Строительно-монтажные работы' : 'Не строительно-монтажные работы' }}
                </label>
              </template>
            </VCheckbox>

            <div class="form__buttons">
              <button
                class="btn btn-primary btn-bg-green"
              >
                Сохранить
              </button>
              <button
                class="btn btn-primary btn-bg-transparent"
                type="button"
                @click="closeAndClear"
              >
                Отмена
              </button>
            </div>
          </div>
        </form>
      </VModal>
  </teleport>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import VModal from '@/components/ui/modal/VModal'
import VSearch from '@/components/ui/form/Search/VSearch'
import VButton from '@/components/ui/buttons/VButton'
import VTable from '@/components/ui/table/VTable'
import VInput from '@/components/ui/form/VInput'
import VCheckbox from '@/components/ui/form/Checkbox/VCheckbox'
import { touchBarCategoryAdmin } from '@/utils/touchbar'
import { mocksSelectList } from '@/mocks/select'
import { useTradeCategoryForm } from '@/use/Form/Admin/useTradeCategoryForm'
import useVModal from '@/use/Base/useVModal'
import { useFilter } from '@/use/Base/useFilter'

export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup () {
    const store = useStore()
    const { showVModal, openVModal, closeVModal } = useVModal()

    const headRow = [
      {
        name: '№ п/п',
        value: 'id',
        filter: true,
        decorator: {
          name: 'link'
        }
      },
      {
        name: 'Товарные категории',
        value: 'name',
        filter: true,
        decorator: {
          name: 'link'
        }
      },
      {
        name: 'Активный',
        value: 'status',
        filter: true,
        decorator: {
          name: 'link'
        },
        sort: 'is_active'
      }
    ]

    const getCategoryFn = async (data) => {
      data.map((el) => {
        if (el.is_active) {
          el.status = 'Активный'
        } else {
          el.status = 'Нет'
        }
      })

      return data
    }

    const rows = computed(() => {
      return store.getters['item/getItems']
    })

    const handlerLink = (item) => {
      store.commit('item/selectedItem', item.item)
      openVModal()
    }
    const closeAndClear = () => {
      store.commit('item/selectedItem', {})
      closeVModal()
    }

    const openModalClickAdd = () => {
      store.commit('item/selectedItem', {})
      openVModal()
    }

    const { input } = useFilter()

    return {
      openModalClickAdd,
      closeAndClear,
      ...useTradeCategoryForm(showVModal),
      handlerLink,
      rows,
      headRow,
      mocksSelectList,
      touchBarCategoryAdmin,
      getCategoryFn,
      input
    }
  },
  components: {
    VSearch,
    VButton,
    VTable,
    VModal,
    VInput,
    VCheckbox
  }
}

</script>
