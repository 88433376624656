import { computed, reactive, watch } from 'vue'
import { useForm } from '../../Base/useForm'
import { requiredValid } from '@/utils/validator/fields'
import { FormHandler } from '@/utils/use/formHandler'
import { setCategoryTable } from '@/http/manualApi'
import { useStore } from 'vuex'

export function useTradeCategoryForm (showVModal) {
  const store = useStore()

  const getSelectElement = computed(() => {
    return store.getters['item/getSelectedItem']
  })

  watch(getSelectElement, () => {
    formData.name = getSelectElement.value.name || ''
    formData.is_active = getSelectElement.value.is_active || false
  })

  const formData = reactive({
    is_active: false,
    name: ''
  })

  const rules = {
    name: requiredValid
  }

  const clearValue = () => {
    formData.name = ''
  }

  const serverErrors = reactive({})

  const formAction = async () => {
    if (store.getters['item/getSelectedItem'].id) {
      const { data } = await setCategoryTable(formData, store.getters['item/getSelectedItem'].id)
      data.status = data.is_active ? 'Активный' : 'Нет'
      store.commit('item/changeItem', data)
    } else {
      const { data } = await setCategoryTable(formData)
      data.status = data.is_active ? 'Активный' : 'Нет'
      store.commit('item/setOneItem', data)
    }
    await store.dispatch('setAlert', {
      type: 'success',
      description: 'Категория изменена'
    })
    showVModal.value = false
    document.body.classList.remove('no-scroll')
  }

  const form = FormHandler(formAction)

  return {
    ...useForm(rules, formData, form.handlerErrors),
    serverErrors,
    showVModal,
    clearValue
  }
}
